<template>
  <div>
    <c-card title="안전밸브 및 파열판 목록 업로드 (동일한 계기번호 데이터는 자료가 수정됩니다)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'valve-rupture-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['valveRuptureNo'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_VALVE_RUPTURE_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:110px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'valveRuptureNo',
            // 계기번호
            label: '계기번호',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'inMaterial',
            // 내용물
            label: '내용물',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'conditionVal',
            // 상태
            label: '상태',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'outVolume',
            // 배출용량(kg/hr)
            label: '배출용량(kg/hr)',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'regulVolume',
            // 정격용량(kg/hr)
            label: '정격용량',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'nozzleSizeIn',
            label: '노즐크기입구',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'nozzleSizeOut',
            label: '노즐크기출구',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'protectSerial',
            label: '보호기기압력기기번호',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'protectOperation',
            label: '보호기기압력운전(MPa)',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'protectDesign',
            label: '보호기기압력설계(MPa)',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'valveSetting',
            label: '안전밸브 등 설정(Mpa)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'safetyValveMaterial',
            // 안전밸브 등 몸체재질
            label: '안전밸브 등 몸체재질',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'safetyValveTrim',
            // Trim재질
            label: '안전밸브 등 Trim재질',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'precisionVal',
            // 정밀도(오차범위)
            label: '정밀도(오차범위)',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'outConnect',
            // 배출연결부위
            label: '배출연결부위',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'outReason',
            // 배출원인
            label: '배출원인',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'formVal',
            // 형식
            label: '형식',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:250px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다.\n\r데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면,\n\r동일한 계기번호 데이터는 자료가 수정됩니다.\n\r그대로 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
